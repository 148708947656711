@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  padding: 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 140px);
    margin: 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

/* .rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
} */

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    flex-wrap: wrap;
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: unset;
    width: min(calc(var(--contentMaxWidth) + 72px), 100vw);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 50px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 50px;
    flex-basis: 382px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  /* Layout */
  width: 100%;
  margin: 0;
  line-height: 40px;
  letter-spacing: -1px;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  color: #B0B0B5;
  font-weight: 700;
  font-size: 18px;

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  font-weight: 500;
  color: #4A4A4A;
  font-size: 14px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }

  @media screen and (max-width: 450px) {
    & button {
      max-width: 340px;
    }
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }

  @media (max-width: 674px) {
    max-width: 420px;
    height: 50vh;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.simpleTextCard {
  border: 1px solid #B2B2B2;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1 1 200px;
  margin-right: 10px;
  max-width: 50%;

  @media(--viewportSmallMax) {
    margin-bottom: 10px;
    max-width: 100%;
    margin-right: 0;
  }
}

.simpleTextCard:last-child {
  margin-right: 0;
}

.border {
  width: 7px;
  background-color: #4871F7;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cardLabel {
  color: #B0B0B5;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.cardValue {
  color: #231F20;
  font-size: 22px;
  font-weight: 700;
}

.cardInfo {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}

.cardsWrapper {

  @media(--viewportMediumMax) {
    margin: 20px 25px;
  }

  @media(--viewportLargeMax) {
    max-width: 470px;
  }

  @media(--viewportSmallMax) {
    max-width: 340px;
  }
}

.productDescriptionWrapper {
  margin: 0 25px;

  @media(--viewportMedium) {
    margin: 0;
  }
}

.productDescription {
  font-size: 46px;
  color: #4871F7;
  font-weight: 700;
  margin: 0;
  line-height: 46px;
}

.listingCategory{
  font-weight: 500;
  font-size: 18px;
  color: #4A4A4A;
}

.pricesSection {
  margin-top: 40px;
  margin-bottom: 40px;

  @media(--viewportMediumMax) {
    margin: 40px 25px;
  }
}

.pricesWrapper {
  display: flex;
  justify-content: space-between;

  @media(--viewportSmallMax) {
    flex-direction: column;

    & div {
      margin-bottom: 10px;
    }
  }
}

.pricesTip {
  font-weight: 700;
  font-size: 13px;
  color: #4871F7;
}

.tipSymbol {
  font-size: 10px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #4871F7;
}

.pricesLabel {
  color: #4A4A4A;
  font-weight: 700;
  font-size: 13px;
  display: block;
}

.rfqPriceSection {
  font-weight: 700;
  font-size: 30px;
  color: #4871F7;
}

.price {
  font-weight: 700;
  font-size: 20px;
  color: #4871F7;
}

.pricesTip::before {
  content: "!";
    display: inline-block;
    text-align: center;
    font-size: 11px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50%;
    background-color: #4871F7;
    color: #fff;
    margin-right: 3px;
}

.aboutThisProduct {
  font-weight: 500;
  font-size: 14px;
  color: #B0B0B5;
}

.perLiterCharge {
  font-weight: 500;
  font-size: 14px;
  color: #4A4A4A;

  margin-right: 25%;
}

.perLiterChargeLabel {
  font-weight: 700;
  font-size: 14px;
  color: #4A4A4A;
}

.perLiterChargeWrapper {
  display: flex;
  justify-content: space-between;
}

.perLiterChargeContainer {
  margin-top: 20px;
  margin-bottom: 20px;

  @media(--viewportMediumMax) {
    margin: 20px 25px;
  }
}

.perLiterChargeTitle {
  font-weight: 700;
  font-size: 18px;
  color: #B0B0B5;
  margin: 0 0 10px 0;
  line-height: 1.4;
}

.relatedListingsRoot {
  margin-bottom: 100px;
  margin: 0 20px;

  @media(--viewportMedium) {
    margin: 0;
  }
}

.relatedListingsTitle {
  font-weight: 700;
  font-size: 46px;
  color: #4A4A4A;
  line-height: 1;
  text-align: left;
}

.relatedListingsInfo {
  font-weight: 500;
  font-size: 18px;
  color: #4A4A4A;
  margin: 0;
}

.relatedListingsSection {
  display: flex;
  flex-wrap: wrap;
}

.relatedListing {
  width: 315px;
  margin-right: 20px;

  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
  }

  @media(--viewportSmallMax) {
    margin-right: 0;
  }
}

.relatedListingPricesLabel {
  font-weight: 700;
  font-size: 9px;
  color: #4A4A4A;
  line-height: 1;
}

.relatedListingDescription {
  margin: 20px 0 10px;
  font-weight: 700;
  font-size: 25px;
  color: #4A4A4A;
  word-break: break-word;
}

.relatedListingPrice {
  font-weight: 700;
  font-size: 17x;
  color: #4871F7;
}

.relatedListingsPricesWrapper {
  display: flex;
  justify-content: space-between;
}

.relatedListingPriceWrapper {
  display: flex;
  flex-direction: column;
}

.aspectRatioWrapper {
  background: var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.richTitle {
  font-weight: 700;
  font-size: 30px;
  color: #4871F7;
}

.dummy {
  width: 225px;
  height: 225px;
  background-image: url('../../../public/static/banners/ListingBanner.png');
  background-size: cover;
  overflow: hidden;
  border-radius: 5px;
}

.sectionHeaderImagesWrapper {
  width: 100%;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  padding: 0;

  @media(--viewportMedium) {
    padding: 4px;
  }

  @media(--viewportLarge) {
    padding: 6px;
  }
}

.sectionHeaderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 8px;
}

.sectionHeaderNoImage {
  background-color: #4871F7;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 13px;
}

.sectionHeaderImages {
  position: relative;
  padding: 0;

  @media(--viewportMedium) {
    padding: 12px 32px;
  }

  @media(--viewportLarge) {
    padding: 12px 48px;
  }
}

.sectionHeaderImagesAspectWrapper {
  width: 100%;
  padding-bottom: 70%;
  position: relative;

  @media(--viewportMedium) {
    padding-bottom: 34%;
  }
}

.sectionHeaderImagesActionImage {
  display: block;
}

.sectionHeaderImagesContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobileContainer {
  @media(--viewportMedium) {
    display: none !important;
  }
}

.sectionHeaderImagesRow {
  width: 100%;
  height: 100%;
  display: flex;
}

.desktopContainer {
  display: none !important;

  @media(--viewportMedium) {
    display: flex !important;
  }
}

.sectionHeaderImagesCol1 {
  flex: 1 0 65%;
  height: 100%;
}

.sectionHeaderImagesCol2 {
  flex: 1 0 35%;
  height: 100%;
}

.sectionHeaderImagesCol {
  width: 100%;
  height: 100%;
}

.sectionHeaderImagesRowSmall {
  height: 50%;
}

.simpleCardsContainer {
  display: flex;

  @media(--viewportSmallMax) {
    display: block;
  }
}

.arrowIcon {
}

.sliderBtn {
}

.sliderBtn {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #D8DCE6;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  position: absolute;
}

.sliderBtnLeft {
  left: 0;
}

.sliderBtnRight {
  right: 0;
}

.relatedListingInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
